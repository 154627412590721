
body, html {
  padding:0;
  margin:0;
  width:100%;
  font-family: 'Fira Sans', sans-serif;
}

#root {width:100%;}

div#rightsidebar {
  position: absolute;
  float: left;
  width: 250px;
  background-color: #2a2a2a!important;
  height: 100%;
  bottom: 0;
  color: #fff;
  text-align: left;
}
#rightsidebar .logo-wrapper .logo{margin: 13px 20px;}

#rightsidebar hr.header{ margin-top: 0px;}
#rightsidebar hr{ border-color: hsla(0,0%,100%,.12);}

.logo-title{ margin: 5px 0; }
.logo-subtitle{ margin: 0; color: hsla(0,0%,100%,.54);}

.right-menu-tree{list-style-type: none;padding: 0px;}
.sidebar-menu-item.active button, .sidebar-menu-item button:hover{color: #fff;}

.sidebar-menu-button, .sidebar-menu-toggle-icon {
  color: hsla(0,0%,100%,.54);
  background: transparent;
  border: none;
  padding: 3px 10px;
  width: 100%;
  text-align: left;
}
.sidebar-menu {
    margin-bottom: 18.75px;
    padding: 0;
    list-style: none;
}
a.sidebar-menu-button {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 42px;
  padding: 0 18.75px;
  font-size: 1rem;
  text-decoration: none;
}
.sidebar-menu-icon{margin-right:5px;}

div#wrapperbody {
  position: absolute;
  width: calc(100% - 250px);
  right: 0;
}

.page_container, .page_heading-container {
  max-width: 1500px;
}
.page_container {
  font-size: 14px;
}
div#datetimecontainer {
  position: fixed;
  z-index: 9999;
  right: 0;
  top: 0;
  padding: 0 5px;
}

#innerbody #header {
  margin: 40px 0 20px;
}

#codex-tree-container ul, #plenum-tree-container ul {
  padding: 0;
  list-style-type: none;
  font-size: 14px;
}

#codex-tree-container ul ul , #plenum-tree-container ul ul{
  /*display: none;/**/
  padding-left: 15px;
}
#codex-tree-container li.active>ul, #plenum-tree-container li.active>ul {
  display: block;
}

.fs-fa-empty {
  display: inline-block;
  width: 14px;
  height: 14px;
}

.resource_title, .resource_title2 {
  max-width: 230px;
  height: 20px;
  overflow: hidden;
  display: inline-block;
  padding-top: 3px;
  margin-bottom: -2px;
  white-space: nowrap;
}

#resource-create {
  display: none;
}

.resource_add_child, .isfolder-btn, .resource_title{
  cursor: pointer;
}
.resource_title:hover{
  text-decoration: underline;
}
.sub-list{
  list-style: none;
}