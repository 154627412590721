.imgPreview{
    height: 200px;
    width: 500px;
}
.imgPreview img{
    width: 100%;
    height: 100%;
}
.suggestions-promo{
    display: none !important;

}