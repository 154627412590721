.callback-bt {
    background: #38a3fd;
    border: 2px solid #38a3fd;
    border-radius: 50%;
    box-shadow: 0 8px 10px rgb(56 163 253 / 30%);
    cursor: pointer;
    height: 68px;
    text-align: center;
    width: 68px;
    right: 8%;
    bottom: 18%;
    z-index: 999;
    transition: .3s;
    /*margin-left: 35%;/**/
    -webkit-animation: hoverWave linear 1s infinite;
    animation: hoverWave linear 1s infinite;
}
.hang-up{
    background: red !important;
}
.callback-bt i {
    color: #fff;
    font-size: 34px;
    transition: .3s;
    line-height: 66px;
    transition: .5s ease-in-out;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#CallListinner {
    position: absolute;
    bottom: 10px;
    width: 150px;
    left: 10px;
}
.video_container {
    position: relative;
}
.video_container:hover #VideoCallPause, .video_container:hover #VideoCallPlay {
    display:block;
}

#VideoCallPause, #VideoCallPlay {
    display:none;
    position: absolute;
    left: 40%;
    top: 40%;
    height: 20%;
    width: 20%;
    z-index: 99;
    font-size: 64px;
    text-align: center;
    opacity: .7;
    cursor:pointer;
}

#VideoCallPause:hover, #VideoCallPlay:hover {
    opacity: .95;
}